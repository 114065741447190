<template>
    <div style="border-radius: 8px" class="row">
        <div class="border">
            <div class="card p-2 pb-0 filter">
                <div class="row w-100 pe-lg-0 me-1 py-2">
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <div class="form-check">
                                <input v-model="checkMark.product_name" class="form-check-input" id="product_name"
                                       type="checkbox" tabindex="3"/>
                                <label class="form-check-label" for="product_name"> Product Name</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <div class="form-check">
                                <input v-model="checkMark.product_sku" class="form-check-input" id="product_sku"
                                       type="checkbox" tabindex="3"/>
                                <label class="form-check-label" for="product_sku"> Product Sku</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <div class="form-check">
                                <input v-model="checkMark.grn_date" class="form-check-input" id="grn_date"
                                       type="checkbox"
                                       tabindex="3"/>
                                <label class="form-check-label" for="grn_date">GRN Date</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <div class="form-check">
                                <input v-model="checkMark.po_number" class="form-check-input" id="po_no" type="checkbox"
                                       tabindex="3"/>
                                <label class="form-check-label" for="po_no">PO No</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <div class="form-check">
                                <input v-model="checkMark.pi_no" class="form-check-input" id="pi_no" type="checkbox"
                                       tabindex="3"/>
                                <label class="form-check-label" for="pi_no">PI No</label>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <label for="quantity">Quantity</label>
                            <input v-model="grn.quantity" class="form-control" id="quantity" type="number"/>
                        </div>
                    </div>
                    <div class="col-md-2 col-12">
                        <div class="mb-1">
                            <button onclick="window.print()" class="btn btn-primary mt-1">Print</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card p-2">
                <div class=" label-border-outer ">
                    <div class="label-border-internal">
                        <div v-for="index in barcodeQty" :key="index" class="sticker-border text-center loop-dev">
                            <div style="display:inline-block;vertical-align:middle;line-height:15px !important;">
                                <span v-if="checkMark.product_name" class="text-label">{{ grn.product_name }}</span>
                                <span v-if="checkMark.product_sku" class="text-label">{{ grn.product_sku }}</span>
                                <span v-if="checkMark.grn_date" class="text-label">{{ grn.grn_date }}</span>
                                <span v-if="checkMark.po_number" class="text-label">{{ grn.po_number }}</span>
                                <span v-if="checkMark.pi_no && grn.pi_no.length" class="text-label">
                                  <template v-for="pi_no in grn.pi_no">
                                     {{ pi_no }}<br>
                                  </template>
                                </span>
                                <img class="center-block" :src="`data:image/png;base64,${barcode}`">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {computed, onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import handleInventory from '@/services/modules/inventory'

const route = useRoute();
const router = useRouter();

let barcode = ref(null);
let grn = ref({
    quantity: '',
    grn_date: '',
    po_number: '',
    pi_no: '',
    product_name: '',
    product_sku: '',
});
let checkMark = ref({
    grn_date: false,
    po_number: false,
    pi_no: false,
    product_name: true,
    product_sku: true,
})

const {fetchSingleRNGeneral} = handleInventory()
let companyId = computed(() => route.params.companyId);
const getGrnGeneral = async (id) => {
    try {
        const res = await fetchSingleRNGeneral(id, '?company_id=' + companyId.value);
        if (res.status) {
            grn.value = res.data;
            barcode.value = res.barcode;
        } else {
            grn.value = {};
            barcode.value = '';
        }
    } catch (err) {
        if (err.response) {
            showError(err.response.message)
        }
    }
}

const barcodeQty = computed(() => parseInt(grn.value.quantity));

onMounted(async () => {
    if (route.query.grnItemId) {
        await getGrnGeneral(route.query.grnItemId)
    }
})
</script>

<style>
.text-center {
    text-align: center;
}

.loop-dev {
    height: auto;
    padding-bottom: 5px;
    line-height: 1.0000in;
    width: 1.94in !important;
    display: inline-block;
}

.text-label {
    font-size: 14px;
    display: block;
    margin: 3px
}

/*Css related to printing of barcode*/
.label-border-outer {
    line-height: 16px;
    page-break-after: always;
    border: 0.1px solid grey !important;
}

.label-border-internal {
    margin: 24px;
}

.sticker-border {
    border: 0.1px dotted grey !important;
    overflow: hidden;
    box-sizing: border-box;
}

@media print {
    .content-wrapper {
        border-left: none !important; /*fix border issue on invoice*/
    }

    .label-border-outer {
        border: none !important;
    }

    .label-border-internal {
        border: none !important;
    }

    .sticker-border {
        border: none !important;
    }

    #toast-container {
        display: none !important;
    }

    .tooltip {
        display: none !important;
    }

    .btn, .main-menu, .header-navbar, .filter {
        display: none !important;
    }

    .app-content, .card {
        padding: 0px !important;
    }

    .label-border-internal {
        margin: 0px !important;
    }

    @page {
        margin: 0;
    }
}

@media print {
    #preview_body {
        display: block !important;
    }
}

@page {
    size: 8.5000in 11.0000in ;
    margin-top: 0in;
    margin-bottom: 0in;
    margin-left: 0in;
    margin-right: 0in;

}
</style>
